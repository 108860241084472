<template>
  <div class="wrap">
    <header class="cursorP">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/enterprise/netJob' }">当前位置：机构招聘会</el-breadcrumb-item>
        <el-breadcrumb-item >{{detail.name}}</el-breadcrumb-item>
      </el-breadcrumb>
    </header>
    <section>
      <div class="box flex">
        <div class="imgs flexs">
          <img :src="detail.imgUrl" alt class="err_image" />
        </div>
        <div class="box_text">
          <h3>{{detail.name}}
            <small v-show="detail.ingType == 1" class="green">报名中</small>
            <small v-show="detail.ingType == 2" class="waring">进行中</small>
            <small v-show="detail.ingType == 3" class="gray">已结束</small>
          </h3>
          <div class="number" v-if="detail.jobFairStatics">
            <p class="address">举办地点：{{detail.provinceid}}</p>
            <p class="p1">
              报名时间：2020-03-03至2020-03-04
            </p>
            <div class="buttons " v-show="detail.ingType != 3">

              <el-button type="primary" plain  v-if="detail.jobFairStatics.jobs > 0"  disabled><i class="el-icon-s-order"></i>已参会</el-button>
              <el-button type="primary" plain v-else @click="goRouter(
                        '/enterprise/joinJob/',
                        detail.id,
                        detail.name,detail.ingType
                      )"><i class="el-icon-s-order"></i>参会报名</el-button>
            </div>
            <span>{{ detail.jobFairStatics.companyCount }}</span>家企业报名成功
          </div>
        </div>
      </div>
    </section>
    <div class="detail">
      <h4 class="flexs">活动详情</h4>
      <div class="dox">
        <div>
          <div class="text" v-html="detail.decription"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "netDetail",
  data(){
    return{
      detail:[],
      id:'',
    }
  },
  methods: {
    apply() {
      this.$message.success("参会成功");
    },
    async netDetail() {
      try {
        let res = await this.$api.jobfairDetail({id:this.id});
        console.log(res);
        if (res.data.success) {
          this.detail = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      } catch (error) {}
    },
    goRouter(url, id, name, ingType) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id,
          name: name,
          ingType: ingType,
        },
      });
      window.open(href);
    },
  },
  created(){
    this.id = this.$route.query.id;
    if (this.id){
      this.netDetail();
    }
  },
};
</script>

<style lang="less" scoped>
@import "~assets/css/public";
/deep/ .el-breadcrumb__item .el-breadcrumb__inner.is-link,
.el-breadcrumb__inner a {
  font-weight: 400 !important;
}
.wrap {
  width: 1200px;
  margin:20px auto 0 auto;
  box-sizing: border-box;
  header {
    margin-bottom: 15px;
  }
  section {
    padding: 20px 15px;
    background-color: #ffffff;
    margin-bottom: 20px;

    .box {
      .imgs {
        width: 500px;
        height: 280px;
        overflow: hidden;
        margin-right: 15px;
        img {
          width: auto;
          height: 100%;
        }
      }
      .box_text {
        flex: 1;
        position: relative;
        margin-top: 10px;
        h3{
          font-size: 18px;
        }
        small{
          display: inline-block;
          width: 66px;
          height: 25px;
          line-height: 25px;
          color: white;
          font-size: 16px;
          font-weight: 400;
          border-radius: 3px;
          text-align: center;
          margin-left: 5px;
        }
        .green{
          background: #00924b;
        }
        .waring{
          background: #FFA200;
        }
        .gray{
          background: #999999;
        }
        i {
          margin-right: 10px;
        }
        p{
          margin-bottom: 10px;
          color: #666666;
        }
        .p1 {
        }
        .p2 {
          span {
            color: #00924c;
          }
        }
        .number{
          position: absolute;
          left: 0;
          bottom: 0;
          color: #666666;
          .buttons{
            display: inline-block;
          }
          .el-button{
            border: none;
            width: 100px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            padding: 0;
            margin-right: 20px;
            i{
              margin-right: 5px;
            }
          }
          span{
            color: @00;
          }
        }
      }
    }

  }
  .detail {
    background: #ffffff;
    position: relative;
    padding-bottom: 30px;
    h4 {
      width: 112px;
      height: 48px;
      color: #00924c;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    }
    .dox {
      margin: 30px 70px 25px 24px;
      .text {
        margin: 15px 0px;
      }
    }
  }
  .detail::after {
    content: "";
    display: block;
    width: 112px;
    height: 4px;
    background-color: #00924c;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>